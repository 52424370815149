@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body{
    max-width: 600px;
    padding : 0px 30px;
    margin: 0 auto;
    position: relative;
    border-top: 6px solid #E8907A;
    padding-bottom: 100px;
}
#root {
    margin-bottom: 3em;

}

header{
    margin-top: 30px;
    margin-bottom: 25px;
}
header > h1{
    margin : 0px 25px;
}

/* Input item */

form#item-input{

    /* position: relative; */
    display: flex;
    border-radius: 5rem;
    border: 2px solid black;
    align-items: center;
}
input[type=text] {
    height: 40px;
}
form#item-input > textarea{
    width: calc(100% - 2rem);
    
    resize: none;
    font-size: 1.5rem;
    height: 3rem;
    line-height: 3rem;
    outline: none;
    border: none;
    margin: 0  0 0 2rem;
}

form#item-input > button{
   /* position: absolute; */
    width: 40px !important;
    margin: 5px;
    aspect-ratio : 1 / 1;
    border-radius: 300px;
    color: white;
    background-color: #E8907A;
    border: none;
    font-size: 1.5rem;
    outline: none;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* Build shopping list */

.booknote-list{
    position: relative;
    margin-top: 10px;
    padding: 20px 0px;
}

ol{
    list-style-type: none;
    padding: 0;
    margin :0
}

.booknote-list > ol > li {
    padding-left: 20px;
    position: relative
}

.editable-shopping-list > ol > li{
    border-bottom: 1px solid #152d3552;
}
.editable-shopping-list > ol > li > div{
    display: flex;
}
.booknote-list  .item-label, .editable-shopping-list form input, .category-label, .list-name{
    flex-grow: 1;
    font-family: 'Roboto', serif;
    letter-spacing: 1px;
    padding-left: 4px;
    font-size: 1.4em;
    align-items: center;
    display: flex;
}
.list-name {
    padding: 0.5em 0.5em 0.5em 4px;
    font-size: 1em;
    border: none;
    outline: none;
}

.editable-shopping-list form{
    display: flex;
    flex-grow: 1;
}
.editable-shopping-list form input{
    border: none;
    outline: none;
}
.booknote-list p{
    margin: 0;
}
.booknote-list p:not(.item-addition-explanation){
    flex-grow: 1;
}
.item-addition-explanation{
    color: gray;
    font-size: 1.1rem;
    margin-right: 15px;
}

.booknote-list .row-options-button-container{
    display: flex;
    align-items: center;
}

.small-button{
    background-color: transparent;
    border: none;
    color: #060e1063;
    font-size: 1.4rem;
    height: 2rem;
    width: 2rem;
    margin: 0;
    padding: 0;
    border-radius: 2rem;
    padding-top: 2px;
    padding-left: 1px;
    outline: none;
    transition: background-color .5s;;
}
.small-button.active{
    background-color: #e8907a94;
}

button.category-icon{
    border: none;
    background-color: transparent;
    outline: none;
}

.booknote-list .vertical-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #E8907ACC;
    left: 20px;
}


ol.category-choice-menu{
    list-style-type: none;
    padding: 0;
}

.category-choice-menu > li{
    display: inline-block;
}

.category-label{
    display: flex;
}


.while-shopping-row{
    display: flex;
    padding-left: 40px;
    align-items: center;
    font-size: .7rem;
    margin-bottom: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.category-listing{
    border-top: 2px solid gray;
}

.switch-and-share {
    display: flex;
    margin-bottom: 1rem;

    justify-content: space-around;
}

.slider:hover {
    border-color: #E8907A;
}
.switch-mode {
    position: relative;
    width: 10rem;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #E8907A;
    font-family: 'Roboto', serif;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #707070;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 2.2em;
    width: 2.2em;
    left: 3px;
    bottom: 5px;
    background-color: #E8907A;
    transition: transform cubic-bezier(0.18, 0.89, 0.11, 0.94) .1s;
    z-index: -1;
    border-radius: 50%;
}

.switch-mode.active > .slider:before {
    transform: translateX(7.1rem);
}
.switch-mode button{
    margin : 0px 6px;
    font-size: 1.2rem;
}
.switch-mode button.active-mode{
    color: white
}

.share-button {
    display: flex;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    justify-content: space-around;
    align-items: center;
    width: 12em;
    gap: 5px;
}

.share {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Mono', serif;
    font-weight: bold;
    color: #707070;
    flex-wrap: wrap;
    font-style: italic;
    justify-content: center;
    max-width: 14em;
}
.share:after {
    content: '';
    width: 75%;
    height: 4px;
    background-color: #E8907A;
    position: relative;
    top: 5px;
    flex-grow: 4;
    grid-area: line;
    transform: translateX(20%);
}

.share > * {
    margin: 0;
    justify-self: end;
    align-self: end;
}



input[type="checkbox"] {
    position: relative;
    width: 1.75rem;
    min-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
    color: #363839;
    border: 1px solid #bdc1c6;
    border-radius: 2rem;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
input[type="checkbox"]::before {
    position: absolute;
    content: '';
    display: block;
    top: 0.20rem;
    left: 0.55rem;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}
input[type="checkbox"]:checked {
    color: #fff;
    border-color: #E8907A;
    background: #E8907A;
    border-radius: 2rem;
}
input[type="checkbox"]:checked::before {
    opacity: 1;
}
input[type="checkbox"]:checked ~label::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.while-shopping-row label,.while-shopping-row .label {
    width: 100%;
    text-align: left;
    flex-grow: 3;
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    padding: 0 0.25em 0;
    user-select: none;
}
.while-shopping-row label::before {
    position: absolute;
    content: attr(data-content);
    color: #9c9e9f;
    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    text-decoration: line-through;
    text-decoration-color: #363839;
    transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

}

.install-prompt{
    display: flex;
    justify-content: revert;
    margin: 20px;
    padding: 5px 5px 5px 1.5rem;
    border: 2px solid #E8907A;
    border-radius: 10px;
    font-family: 'Roboto', serif;
    color: #707070;
    font-size: 1.2rem;
}

.plain-button{
    border-radius: 50px;
    color: white;
    background-color: #E8907A;
    border: none;
    font-size: 1rem;
    outline: none;
    font-family: 'Roboto Mono', serif;
    align-self: center;
    padding: 1em;
    font-weight: bold;
}
button.close {
    align-self: flex-start;
}
button.small-button:hover, button.small-button.active {
    color: #E8907A;

}
.plain-button:hover {
    background-color: white;
    border: 2px solid #E8907A;
    color: #E8907A;
}
button:not(:disabled) {
    cursor: pointer;
}
.map_history{
    width: 100%;
}

#mapid{
    height: 600px;
}

.icon-circle {
    background-color: #E8907A;
    border-radius: 2rem;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}


.list-actions{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 5px;
}
button.list-action-button:hover {
    border-color: #E8907A;
    color: #707070;
}
button.list-action-button {
    min-width: 250px;
    border-radius: 50px;
    color: #E8907A;
    background-color: white;
    border: 2px solid #707070;
    font-size: 1rem;
    outline: none;
    font-family: 'Roboto Mono', serif;
    padding: 0.25em 0.75em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
button.list-action-button.really{
    color : white;
    background-color: #E8907A;
    border-color: #E8907A;
}


.snackbar{
    position: fixed;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(0, 0, 0, .8);
    padding: 4px 20px;
    min-width: 20%;
    min-height: 3rem;
    border-radius: 4px;
    align-items: center;
    color : white;
    justify-content: center;
    font-family: 'Roboto', serif;
    letter-spacing: 1px;
    bottom: -20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity ease-out .3s, bottom  10s, visibility .3s;
}
.snackbar.visible{
    display: flex;
    bottom: 10px;
    visibility: visible;
    transition: bottom ease-out .1s;
    opacity: 1;
}
.empty-list {
    margin-left: 3rem;
    font-family: 'Roboto', serif;
    color: #707070;
    font-style: italic;
}


/* switch list */

.menuToggle button.open-close {
    border-radius: 5em 5em 0em 0em;
    width: 100px;
    height: 50px;
    background-color: #E8907A;
    border: 0;
    color: white;

    position: relative;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);

}




/* #list-choice ul li button {
    color: #E8907A;
    border: 0;
    background-color: transparent;
} */
#list-choice ul li {
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    border-bottom: 2px solid #707070;

}

#list-choice ul {
    margin: auto;
    padding-left: 0;
    list-style-type: none;
}


#option-buttons{
    position: absolute;
    bottom: 1.7rem;
    right: 1.5rem;
}

.menu-button{
    background-color: #E8907A;
    color: white;
    font-size: 1.5rem;
    border-radius: 5rem;
    height: 60px;
    width: 60px;
    border: none;
    box-shadow: 4px 4px 10px 5px #cccccc9c;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

#options-menu-container{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    top: 0;
}
#options-menu{
    position: relative;
    height: 100%;
    max-width: 660px;
    margin: 0 auto;
}
#options-menu > *{
    pointer-events: all;
}

#list-choice-popup{
    visibility: hidden;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    transition: visibility .3s;
}
#list-choice-popup.opened{
    visibility: visible;
}
#list-choice-popup-background{
    outline: none;
    border: none;
    cursor: default;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    background-color: transparent;
    transition: background-color ease .3s;
}
#list-choice ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    border-bottom: 2px solid #707070;
    font-family: 'Roboto Mono', serif;
    font-weight: bold;
}
#list-choice-popup.opened #list-choice-popup-background{
    background-color: #f7f7f7ad;
}
.menuToggle ul li {
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    border-bottom: 2px solid #707070;
}

#popup-content{
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
    border-top: 6px solid #E8907A;
    background-color: white;
    z-index: 1;   
    overflow-y: auto;
    padding: 30px 20px;
    box-shadow:  1px -6px 13px 4px #b9b9b99c;
    transition: top ease .15s, box-shadow ease .3s, border-top-width ease .3s;
    border-radius: 3em 3em 0 0;
    padding-top: 1em;
}
#list-choice ul li .active {
    color: #E8907A;
    font-weight: bolder;
}
#list-choice ul {
    max-width: 600px;
    margin: auto;
}



.item-label{
    transition: color .5s;
}
.item-label.bought{
    text-decoration: line-through;
    color: rgba(0,0,0,.4)
}


button, a, .while-shopping-row{
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.item-label{
    user-select: none;
}
li.frequent-article{
    display: flex;
    font-family: 'Roboto';
}
li.frequent-article > p{
    flex-grow: 1;
}

li.frequent-article > button.add-item-button{
    background-color: #E8907A;
    color : white;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    border: none;
}

.see-more{
    margin: 20px auto
}

.frequent-article-tip{
    font-family: Roboto;
    font-family: 'Roboto';
    color: #707070;
    text-align: center;
    padding : 20px;
}

@keyframes patate{
    0%   {transform: rotate3d(1, 1, 1,0deg);}
    25%  {transform: rotate3d(1, 1, 1,90deg);}
    50%  {transform: rotate3d(1, 1, 1,180deg);}
    75% {transform: rotate3d(1, 1, 1,270deg);}
    100% {transform: rotate3d(1, 1, 1,360deg);}
}

/* :not(body):not(div):not(header):not(html){
    transform-origin: bottom;
    animation : patate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
} */



/**  Row options **/
.row-options-container{
    position: relative;
}

.contextual-menu{
    position: absolute;
    right: 0px;
    top: 40px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    padding: 0;
    background-color: white;
    z-index: 1;
    box-shadow: 4px 4px 10px 5px #cccccc9c;
    border-radius: 10px;
    overflow: hidden;
    transform-origin: right top;
    transform: scale(.1);
    transition: transform .2s, visibility .2s;
}

.contextual-menu.opened{
    visibility: visible;
    width: auto;
    height: auto;
    transform: scaleY(1);
    transition: transform .1s;
}

.contextual-menu button{
    border: none;
    outline: none;
    background-color: white;
    font-size: 1rem;
    padding: 15px 25px
}
.contextual-menu button:hover:not(:disabled){
    background-color: rgba(0,0,0,.2);
}


.row-options-button{
    background-color: transparent;
    border: none;
    color: #060e1063;
    font-size: 1.4rem;
    height: 2rem;
    width: 2rem;
    margin: 0;
    padding: 0;
    border-radius: 2rem;
    padding-top: 2px;
    padding-left: 1px;
    outline: none;
    transition: background-color .5s;;
}
.row-options-button.active{
    background-color: #e8907a94;
}

.f-grow{
    flex-grow: 1;
}
.f-vcenter {
    align-items: center;
}
.flex{
    display: flex;
}

.list-label{
    color : #707070;
}

.popin-action-btn > * {
    margin-top: 1em;

}
.popin-action-btn {
    justify-content: space-around;
    align-items: center;
    margin: 0px auto;
    flex-wrap: wrap;
}

.import-list-form {
    border-radius: 50px;
    color: #E8907A;
    background-color: white;
    border: 2px solid #707070;
    font-size: 1rem;
    font-family: 'Roboto Mono', serif;
    padding: 0.25em 0em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.import-list-form input {
    border: none;
    width: 40%;

}
.import-list-form button div {
    width: 32px;
    height: 32px;
}
.import-list-form button {
    border: none;
    background-color: transparent;
}

